import React from 'react';
import { Copy } from '#components/atoms';

export const dataFaqs: {
  title: string;
  description: React.ReactNode;
  id: string;
  show_home: boolean;
}[] = [
  {
    id: 'web_&_mob',
    show_home: false,
    title: '¿Si tengo software de escritorio, puedo usar la app web y mobile?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Si, una vez hayas ingresado tus datos de usuario en cualquiera de
        nuestras versiones, podrás hacer uso de todas las modalidades que Bilog
        tiene para ofrecerte.
      </Copy>
    ),
  },
  {
    id: 'web_&_mob',
    show_home: false,
    title:
      '¿Cómo puedo descargar la aplicación móvil de Bilog en mi dispositivo?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Puedes descargar la aplicación móvil de Bilog en tu dispositivo de forma
        sencilla. Está disponible tanto en la App Store para dispositivos iOS o
        en Google Play Store para dispositivos Android.
        <br /> Nuestra aplicación es compatible con todos los dispositivos y
        sistemas operativos. A continuación, te proporcionamos los enlaces
        directos para acceder a la descarga:
        <br />
        <div className="flex flex-col">
          <a
            className="underline text-blue-500"
            href="https://apps.apple.com/ar/app/bilog-gesti%C3%B3n-odontol%C3%B3gica/id1554140449"
          >
            Descargar Bilog en App Store.
          </a>
          <a
            className="underline text-blue-500"
            href="https://play.google.com/store/apps/details?id=com.bilog.gomobile"
          >
            Descargar Bilog en Google Play Store.
          </a>
        </div>
      </Copy>
    ),
  },
  {
    id: 'web_&_mob',
    show_home: false,
    title: '¿Qué características ofrece la versión web de Bilog?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Actualmente, nuestra versión web abarca todas las herramientas
        disponibles en la versión de escritorio, incluyendo agenda,
        estadísticas, liquidaciones, gestión de pacientes y administración de
        caja.
        <br /> Además, la facilidad de acceder desde cualquier lugar con
        conexión a internet permite una mayor flexibilidad y conveniencia según
        tus necesidades.
      </Copy>
    ),
  },
  {
    id: 'web_&_mob',
    show_home: false,
    title: '¿Cuál es el proceso para registrarme como usuario en Bilog?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        El proceso para registrarte como usuario en Bilog es simple y rápido,
        tanto en web como en app móvil:
        <p className="mt-5">
          1. Ingresa a la sesión de Bilog.
          <br />
          2. Haz clic en el botón “Registrarme”.
          <br />
          3. Completa el formulario de registro con tus datos.
          <br />
          4. Verifica el código de seguridad proporcionado a travez de tu móvil
          afiliado.
          <br />
          5. Recibirás en tu correo electrónico el usuario de Bilog asignado.{' '}
          <br />
          <br />
          ¡Y eso es todo! Ahora estás listo para empezar a utilizar Bilog y
          aprovechar todas sus funcionalidades.
          <br /> Para iniciar sesión y registrarte, puedes acceder a través de
          este{' '}
          <a
            className="underline text-blue-500"
            href="https://beta.bilog.com.ar/"
          >
            enlace
          </a>
          .
        </p>
      </Copy>
    ),
  },
  {
    id: 'desktop',
    show_home: false,
    title: '¿Es difícil la instalación del software?',
    description:
      'No es difícil, se realiza en forma rápida y sencilla. En minutos tendrá acceso al Software de Gestión Odontológica para Consultorios y Clí­nicas funcionando en su consultorio.',
  },
  {
    id: 'desktop',
    show_home: false,
    title: '¿Que recursos necesito para la instalación del programa?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Con Team Viewer: Puedes compartir tu computadora de forma segura a
        través del siguiente{' '}
        <a
          className="underline text-blue-500"
          href="https://www.teamviewer.com"
        >
          link
        </a>
        .
      </Copy>
    ),
  },
  {
    id: 'desktop',
    show_home: false,
    title: '¿Cuánto demoro en tener el software en funcionamiento?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Una vez confirmada la compra puede demorar 24 horas para efectuarse la
        instalación. Posteriormente soporte se encarga la instalación, la cual
        demora aproximadamente 30 minutos por computadora.
      </Copy>
    ),
  },
  {
    id: 'desktop',
    show_home: false,
    title: '¿Qué tipo de sistema operativo necesito?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Para garantizar la mejor experiencia de usuario, recomendamos utilizar
        Windows 10 o versiones posteriores. En caso de no ser posible, sugerimos
        al menos utilizar Windows 7 con todas sus actualizaciones más recientes.
      </Copy>
    ),
  },
  {
    id: 'desktop',
    show_home: false,
    title: '¿Las actualizaciones del software son gratis?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Si eres parte de nuestro Servicio Plus, todas las actualizaciones son
        gratuitas y se pueden descargar directamente desde el sistema. En caso
        contrario, las actualizaciones que implican mejoras en funciones ya
        existentes son también gratuitas. Sin embargo, para acceder a las nuevas
        versiones, que proporcionan mejoras significativas, existe un costo
        accesible. Todas las actualizaciones se anuncian y están disponibles
        para su descarga en nuestra página web.
      </Copy>
    ),
  },
  {
    id: 'learning',
    show_home: false,
    title: '¿Cómo puedo aprender a usar el software escritorio?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Para aprender a usar el software de escritorio de Bilog, ofrecemos
        varias opciones:
        <br /> 1. Capacitaciones con nuestros profesionales expertos: Brindamos
        sesiones de capacitación personalizadas con nuestros expertos, quienes
        te guiarán a través de todas las funciones y características del
        software según tus necesidades específicas.
        <br />
        2. Recursos de manuales: Ponemos a tu disposición manuales detallados
        que explican paso a paso cómo utilizar cada aspecto del software.
        <br />
        <br /> Estos manuales son una excelente referencia para aprender a tu
        propio ritmo.
      </Copy>
    ),
  },
  {
    id: 'learning',
    show_home: true,
    title: '¿El uso de Bilog requiere conocimientos de informática?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        No, el sistema está diseñado para que cualquier persona pueda utilizarlo
        sin tener conocimientos técnicos. El manejo de la agenda es intuitivo y
        amigable. De todas formas, ofrecemos soporte a nuestros usuarios por
        cualquier duda que pudieran tener.
      </Copy>
    ),
  },
  {
    id: 'learning',
    show_home: false,
    title:
      '¿Qué opciones de soporte técnico están disponibles si encuentro algún problema con Bilog?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Si encuentra algún problema con Bilog, contamos con varias opciones de
        soporte técnico disponibles para ayudarlo. Puede comunicarse con
        nosotros a través de: WhatsApp al número{' '}
        <a className="text-blue-500" href="https://wa.me/5491157999040">
          +54 9 11 5799-9040
        </a>
        . Puedes llamar a nuestra central telefónica al número{' '}
        <a className="underline text-blue-500" href="tel:1152632220">
          11 5263-2220
        </a>{' '}
        . También puede enviarnos un correo electrónico a
        <a className="underline text-blue-500" href="mailto:info@bilog.com.ar">
          {' '}
          info@bilog.com.ar
        </a>{' '}
        para recibir asistencia.
      </Copy>
    ),
  },
  {
    id: 'plans',
    show_home: true,
    title: '¿Hay algún costo de configuración?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        No, no cobramos la configuración inicial, solo deberás abonar el monto
        mensual de tu plan.
      </Copy>
    ),
  },
  {
    id: 'plans',
    show_home: false,
    title: '¿Puedo cambiar de plan?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Si, podés cambiar de plan todas las veces que quieras sin problema. Solo
        deberás avisarle a nuestro equipo para que te suban o bajen de plan y lo
        verás reflejado el cambio en pocas horas.
      </Copy>
    ),
  },
  {
    id: 'plans',
    show_home: true,
    title: '¿Es un pago único? ¿Cómo es el cobro?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Nuestro sistema se abona de forma mensual. Todos los meses recibirás la
        factura por el servicio utilizado, la cual podrás abonar mediante
        transferencia/depósito bancario, o tarjetas de crédito y débito.
      </Copy>
    ),
  },
  {
    id: 'plans',
    show_home: true,
    title:
      '¿Debo contratar el sistema por un tiempo determinado? ¿Tiene permanencia?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        No es necesario, no tenemos contrato de permanencia. Es decir, podés
        contratar Bilog por el tiempo que desees.
      </Copy>
    ),
  },
  {
    id: 'plans',
    show_home: true,
    title: '¿Qué sucede si deseo cancelar el servicio?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Si quieres cancelar el servicio, solo tienes que avisarle a nuestro
        equipo para que hagan el cierre o suspensión de tu cuenta. No deberás
        abonar nada adicional, ya que solo abonas los meses que utilizas el
        servicio.
      </Copy>
    ),
  },
  {
    id: 'limitations',
    show_home: false,
    title:
      '¿Puedo acceder a las apps con mi usuario y contraseña asignados para el software?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Sí, por supuesto, puedes acceder a las aplicaciones utilizando tu
        usuario y contraseña asignados para el software.
      </Copy>
    ),
  },
  {
    id: 'limitations',
    show_home: false,
    title: '¿Qué limitaciones tengo al ser usuario free?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Como usuario gratuito de Bilog, tienes acceso a ciertas funcionalidades
        limitadas:
        <br />
        1. Limitado a un solo usuario y profesional.
        <br />
        2.Diseñado para uso individual, no admite múltiples profesionales.
        <br />
        3.Registro de turnos disponible únicamente para los 30 días a partir de
        la fecha actual.
        <br />
        4.Capacidad para registrar hasta 50 pacientes.
      </Copy>
    ),
  },
  {
    id: 'limitations',
    show_home: false,
    title: '¿Y a que puedo acceder como usuario free?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        1. El recordatorio gratuito de WhatsApp desde la agenda está incluido.
        <br />
        2.Tienes la opción de contratar el paquete de WhatsApp para recordatorio
        y confirmación de turnos, por medio de la sección de “Mi cuenta”.
        <br />
        3. También puedes contratar el Guardado de Imágenes en la nube para una
        mejor gestión de tus archivos y documentos.
        <br />
        <br />
        Todo esto a través de “Mi cuenta”, una vez ingreses a la misma.
      </Copy>
    ),
  },
  {
    id: 'limitations',
    show_home: false,
    title:
      '¿Es posible gestionar la facturación y los pagos de los pacientes a través de Bilog?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Sí, contamos con el servicio de facturación electrónica integrado dentro
        de Bilog. Esto le permite gestionar los pagos de los pacientes y
        vincularlos directamente con la facturación electrónica desde una única
        plataforma. Con Bilog, es posible gestionar de manera eficiente tanto la
        facturación como los pagos de los pacientes desde un mismo lugar.
      </Copy>
    ),
  },
  {
    id: 'auditory',
    show_home: false,
    title: '¿Es difícil la instalación del software de auditorías?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        No es difícil, se realiza en forma rápida y sencilla. En minutos tendrá
        acceso al Software de auditorías odontológica.
      </Copy>
    ),
  },
  {
    id: 'auditory',
    show_home: false,
    title:
      '¿Cuáles son los requisitos necesarios para contratar el software de auditorías?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Para contratar el software, solo necesitas una computadora de escritorio
        con Windows 7 o una versión posterior. Nuestro equipo te proporcionará
        todos los detalles técnicos y te asistirá en todo el proceso de
        configuración y puesta en marcha.
      </Copy>
    ),
  },
  {
    id: 'auditory',
    show_home: false,
    title:
      '¿Necesito tener conocimientos de odontología para usar el software?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        Sí, los usuarios deben tener conocimiento y manejo de la terminología
        odontológica. Nos encargamos de capacitar e instruir al equipo que
        utilizará el sistema.
      </Copy>
    ),
  },
  {
    id: 'auditory',
    show_home: false,
    title:
      '¿Cómo puedo agilizar la subida de prestaciones al software de auditorias odontológicas?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        La mejor manera de agilizar la subida de prestaciones a tus sistemas es
        adquiriendo nuestro <strong>software de gestión odontológica</strong>.
        Este, en conjunto con nuestro software de auditoría, crea un ecosistema
        de trabajo altamente eficiente y efectivo.
      </Copy>
    ),
  },
  {
    id: 'administration',
    show_home: false,
    title: '¿Que incluye el usuario Free de administración?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        El usuario free podrá acceder a la información de su caja diaria, en la
        que podrá ver los pagos de paciente por el día de hoy y hacer cierre de
        caja.
      </Copy>
    ),
  },
  {
    id: 'administration',
    show_home: false,
    title: '¿Qué incluye el módulo completo?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        El módulo completo incluye la caja diaria pero además podras:
        <ul className="pl-4 list-disc">
          <br />
          <li>Ver la caja diaria desde el día que quieras.</li>
          <li>Hacer cierre de caja.</li>
          <li>
            Ver movimientos completos según el rango de fecha que elijas, la
            lista de pagos de pacientes, otros ingresos y egresos varios.
          </li>
          <li>Realizar nuevos ingresos y egresos.</li>
          <li>
            Configurar tus tarjetas de débito y crédito, tus bancos y cuentas.
          </li>
          <li>Agregar y ver los movimientos bancarios.</li>
          <li>Ver los movimientos según proveedor.</li>
        </ul>
        <br />
        Esto y mucho más con el módulo de administración.
      </Copy>
    ),
  },
  {
    id: 'administration',
    show_home: false,
    title:
      'Soy supervisor, ¿Todos mis usuarios pueden acceder a mi información de administración?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        No, con los permisos podes seleccionar y organizar quien puede ver y
        editar tu módulo de administración. Bilog siempre personalizado para tus
        necesidades.
      </Copy>
    ),
  },
  {
    id: 'administration',
    show_home: false,
    title: '¿Cuál es el proceso para registrarme como usuario en Bilog?',
    description: (
      <Copy className="pl-4 lg:!text-base 2xl:!text-xl">
        El proceso para registrarte como usuario en Bilog es simple y rápido,
        tanto en web como en app móvil:
        <p className="mt-5">
          1. Ingresa a la sesión de Bilog.
          <br />
          2. Haz clic en el botón “Registrarme”.
          <br />
          3. Completa el formulario de registro con tus datos.
          <br />
          4. Verifica el código de seguridad proporcionado a travez de tu móvil
          afiliado.
          <br />
          5. Recibirás en tu correo electrónico el usuario de Bilog asignado.
          <br />
          <br />
          ¡Y eso es todo! Ahora estás listo para empezar a utilizar Bilog y
          aprovechar todas sus funcionalidades.
          <br /> Para iniciar sesión y registrarte, puedes acceder a través de
          este{' '}
          <a
            className="underline text-blue-500"
            href="https://beta.bilog.com.ar/"
          >
            enlace
          </a>
          .
        </p>
      </Copy>
    ),
  },
];

import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '#components/atoms';
import { Button, Card, Copy, Icons, Image, Title } from '#components/atoms';
import { menuData } from '#mocks/menu';
import { useScrollNavigation } from '../../hooks';

export const DesktopMenu = () => {
  const [infoToShow, setInfoToShow] = React.useState(1);
  const navigate = useNavigate();
  const handleNavigation = useScrollNavigation();
  const handleMouseEnter = (info: number) => {
    setInfoToShow(info);
  };

  return (
    <Card className="z-50 hidden lg:flex justify-between w-full h-auto !p-0 max-w-screen-2xl mx-auto">
      <NavigationMenu>
        <NavigationMenuList>
          {menuData.map(
            (item: any, index: number) =>
              !item.isNotshowInDesktop && (
                <NavigationMenuItem key={index}>
                  <NavigationMenuTrigger
                    className="font-normal text-slate-800 data-[state=open]:text-slate-950"
                    navLink={item.navLink}
                  >
                    {item.titleDesktop}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent className="bg-white">
                    {item.titlesContainMenu && (
                      <Card
                        className="grid w-[400px] gap-1 lg:grid-cols-12 lg:w-[800px] bg-slate-50 shadow-md"
                        key={index}
                      >
                        <Card className="col-span-5 flex flex-col !p-0">
                          {item.titlesContainMenu?.map(
                            (i: any, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className={`transition-all cursor-pointer ease-in-out delay-150 duration-300 p-4 rounded-md flex ${
                                    infoToShow === i.id && 'bg-white'
                                  } ${
                                    index >= item.titlesContainMenu?.length - 1
                                      ? 'mb-32'
                                      : 'mb-4'
                                  }`}
                                  onMouseEnter={() => handleMouseEnter(i.id)}
                                >
                                  <div>
                                    <Copy className="text-slate-900 font-bold">
                                      {i.title}
                                    </Copy>
                                    <Copy>{i.description}</Copy>
                                  </div>
                                  <div
                                    className={`absolute ml-[293px] flex items-center z-50 mt-5 transition-all ease-in-out delay-150 duration-300 ${
                                      infoToShow === i.id
                                        ? 'opacity-1'
                                        : 'opacity-0'
                                    }`}
                                  >
                                    <svg
                                      width="22"
                                      height="28"
                                      viewBox="0 0 12 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M7.29289 13.2929 0 6v16l7.29289-7.2929c.39053-.3905.39053-1.0237 0-1.4142Z"
                                        fill="#fff"
                                      ></path>
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="m0 27.657 9.41421-9.4142c2.34319-2.3432 2.34319-6.1422.00001-8.48532L0 .343262V6.00012l6.58579 6.58578c.78105.7811.78105 2.0474 0 2.8284L0 22.0001v5.6569Z"
                                        fill="var(--siteSubMenuBackgroundColor, #f0f3f6)"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </Card>
                        <Card className="col-span-7 relative overflow-y-auto no-scrollbar cursor-pointer bg-white mb-2">
                          {item.titlesContainMenu?.map(
                            (i: any, index: number) => (
                              <div
                                key={index}
                                className={`absolute inset-0 transition-all ease-in-out duration-1000 text-white ${
                                  infoToShow === i.id
                                    ? 'transform -translate-y-0 opacity-1'
                                    : 'transform -translate-y-full opacity-0'
                                }`}
                              >
                                <div className="grid grid-cols-2 h-4 p-6">
                                  {menuData
                                    .find((e: any) => e.id === i.id)
                                    ?.details?.map((card: any) => (
                                      <div
                                        key={index}
                                        className="flex flex-row p-2 hover:bg-slate-100"
                                        onClick={() =>
                                          handleNavigation(card.navigation)
                                        }
                                      >
                                        {card.icon ? (
                                          <div className="flex flex-col items-center text-top">
                                            <Icons
                                              type={card.icon}
                                              fill={card.fill}
                                              className="size-7 mb-4 text-blue-400"
                                            />
                                          </div>
                                        ) : (
                                          <Image
                                            src={require(`#assets/icons/${
                                              card.logo || 'icon-bilog.png'
                                            }`)}
                                            width={25}
                                            height={50}
                                            className="h-6 w-8"
                                            alt="icon"
                                          />
                                        )}
                                        <div className="flex flex-col text-left ml-4">
                                          <Title className="!text-base text-slate-900">
                                            {card.title}
                                          </Title>
                                          <Copy className="mb-4 text-sm">
                                            {card.subTitle}
                                          </Copy>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ),
                          )}
                        </Card>
                      </Card>
                    )}
                    {!item.titlesContainMenu && (
                      <Card className="flex flex-col bg-slate-50 shadow-md lg:w-[500px]">
                        {item.details?.map((card: any, i: number) => (
                          <div
                            key={i}
                            className="flex flex-col items-start !p-0 bg-white cursor-pointer hover:bg-slate-100"
                            onClick={() => handleNavigation(card.navigation)}
                          >
                            <Card className="flex flex-row">
                              {card.icon ? (
                                <div className="flex flex-col items-center text-top">
                                  <Icons
                                    type={card.icon}
                                    fill={card.fill}
                                    className="size-7 mb-4 text-blue-400"
                                  />
                                </div>
                              ) : (
                                <Image
                                  src={require(`#assets/icons/${
                                    card.logo || 'icon-bilog.png'
                                  }`)}
                                  width={25}
                                  height={50}
                                  className="h-6 w-8"
                                  alt="icon"
                                />
                              )}
                              <div className="flex flex-col text-left ml-4">
                                <Title className="!text-base text-slate-900">
                                  {card.title}
                                </Title>
                                <Copy className="mb-4 text-sm">
                                  {card.subTitle}
                                </Copy>
                              </div>
                            </Card>
                          </div>
                        ))}
                      </Card>
                    )}
                  </NavigationMenuContent>
                </NavigationMenuItem>
              ),
          )}
        </NavigationMenuList>
      </NavigationMenu>
      <div className="flex items-center gap-x-5">
        <Button
          className="font-bold"
          modifier="white"
          onClick={() => navigate('/contact')}
        >
          Contactanos
        </Button>
        <Button
          className="font-bold"
          onClick={() => window.open('https://beta.bilog.com.ar', '_blank')}
        >
          Ingresa ahora
        </Button>
      </div>
    </Card>
  );
};
